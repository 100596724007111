import { FC, lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import MovieDetails from "../modules/apps/movie-details/MoviePage";
import AddMovie from "../modules/apps/movies/AddMovie";
import EditRevision from "../modules/apps/revision/EditRevision";
import { ContactRequestOverview } from "../modules/apps/contact-request-details/ContactRequestPage";

const PrivateRoutes = () => {
  const UserDetails = lazy(
    () => import("../modules/apps/user-details/ProfilePage")
  );
  const UsersPage = lazy(
    () => import("../modules/apps/user-management/UsersPage")
  );
  const VendorsPage = lazy(
    () => import("../modules/apps/vendor-management/UsersPage")
  );
  const AddSeries = lazy(() => import("../modules/apps/series/AddSeries"));
  const MoviesPage = lazy(() => import("../modules/apps/movies/UsersPage"));
  const RequestsPage = lazy(() => import("../modules/apps/requests/UsersPage"));
  const SeriesDetails = lazy(
    () => import("../modules/apps/series-details/SeriesPage")
  );
  const SeriesVersionDetails = lazy(
    () => import("../modules/apps/series-version-details/SeriesPage")
  );
  const SeasonVersionDetails = lazy(
    () => import("../modules/apps/season-version-details/SeasonPage")
  );
  const MoviesVersionDetails = lazy(
    () => import("../modules/apps/movie-version-details/MoviePage")
  );
  const EpisodeVersionDetails = lazy(
    () => import("../modules/apps/episode-version-details/EpisodePage")
  );
  const SeasonDetails = lazy(
    () => import("../modules/apps/season-details/SeasonPage")
  );
  const EpisodeDetails = lazy(
    () => import("../modules/apps/episode-details/EpisodePage")
  );
  const SeriesPage = lazy(() => import("../modules/apps/series/UsersPage"));
  const CharactersPage = lazy(
    () => import("../modules/apps/characters/UsersPage")
  );
  const AddCharacter = lazy(
    () => import("../modules/apps/characters/AddCharacter")
  );
  const TransactionsPage = lazy(
    () => import("../modules/apps/transactions/TransactionsPage")
  );
  const GenrePage = lazy(() => import("../modules/apps/genre/UsersPage"));
  const EditCharacter = lazy(
    () => import("../modules/apps/characters/EditCharacter")
  );
  const AddSeason = lazy(
    () => import("../modules/apps/series-details/AddSeason")
  );
  const AddEpisode = lazy(
    () => import("../modules/apps/season-details/AddEpisode")
  );
  const Payouts = lazy(
    () => import("../modules/apps/payouts/PayoutsPage")
  );
  const ContactRequestPage = lazy(
    () => import("../modules/apps/contact-requests/UsersPage")
  );


  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />
        {/* Pages */}
        <Route path="dashboard" element={<DashboardWrapper />} />
        <Route
          path="users"
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path="vendors"
          element={
            <SuspensedView>
              <VendorsPage />
            </SuspensedView>
          }
        />
        <Route
          path="movies"
          element={
            <SuspensedView>
              <MoviesPage />
            </SuspensedView>
          }
        />
        <Route
          path="requests"
          element={
            <SuspensedView>
              <RequestsPage />
            </SuspensedView>
          }
        />
        <Route
          path="series"
          element={
            <SuspensedView>
              <SeriesPage />
            </SuspensedView>
          }
        />
        <Route
          path="movies/add"
          element={
            <SuspensedView>
              <AddMovie />
            </SuspensedView>
          }
        />
        <Route
          path="movies/edit"
          element={
            <SuspensedView>
              <AddMovie />
            </SuspensedView>
          }
        />
        <Route
          path="series/add"
          element={
            <SuspensedView>
              <AddSeries />
            </SuspensedView>
          }
        />
        <Route
          path="series/edit"
          element={
            <SuspensedView>
              <AddSeries />
            </SuspensedView>
          }
        />
        <Route
          path="series/season/add"
          element={
            <SuspensedView>
              <AddSeason />
            </SuspensedView>
          }
        />
        <Route
          path="series/season/edit"
          element={
            <SuspensedView>
              <AddSeason />
            </SuspensedView>
          }
        />
        <Route
          path="series/season/episode/add"
          element={
            <SuspensedView>
              <AddEpisode />
            </SuspensedView>
          }
        />
        <Route
          path="series/season/episode/edit"
          element={
            <SuspensedView>
              <AddEpisode />
            </SuspensedView>
          }
        />
        {/* <Route
          path="characters"
          element={
            <SuspensedView>
              <CharactersPage />
            </SuspensedView>
          }
        /> */}
        {/* <Route
          path="characters/add"
          element={
            <SuspensedView>
              <AddCharacter />
            </SuspensedView>
          }
        /> */}
        <Route
          path="requests/edit"
          element={
            <SuspensedView>
              <EditRevision />
            </SuspensedView>
          }
        />
        <Route
          path="character-version/:id/*"
          element={
            <SuspensedView>
              <EditCharacter />
            </SuspensedView>
          }
        />
        {/* <Route
          path="characters/edit"
          element={
            <SuspensedView>
              <EditCharacter />
            </SuspensedView>
          }
        /> */}
        <Route
          path="genres"
          element={
            <SuspensedView>
              <GenrePage />
            </SuspensedView>
          }
        />
        <Route
          path="transactions"
          element={
            <SuspensedView>
              <TransactionsPage />
            </SuspensedView>
          }
        />
        <Route
          path="sales"
          element={
            <SuspensedView>
              <TransactionsPage />
            </SuspensedView>
          }
        />
        <Route
          path="users/:id/*"
          element={
            <SuspensedView>
              <UserDetails />
            </SuspensedView>
          }
        />
        <Route
          path="movies/:id/*"
          element={
            <SuspensedView>
              <MovieDetails />
            </SuspensedView>
          }
        />
        <Route
          path="movie-version/:id/*"
          element={
            <SuspensedView>
              <MoviesVersionDetails />
            </SuspensedView>
          }
        />
        <Route
          path="series/:id/*"
          element={
            <SuspensedView>
              <SeriesDetails />
            </SuspensedView>
          }
        />
        <Route
          path="series-version/:id/*"
          element={
            <SuspensedView>
              <SeriesVersionDetails />
            </SuspensedView>
          }
        />
        <Route
          path="episode-version/:id/*"
          element={
            <SuspensedView>
              <EpisodeVersionDetails />
            </SuspensedView>
          }
        />
        <Route
          path="series/season/:id/*"
          element={
            <SuspensedView>
              <SeasonDetails />
            </SuspensedView>
          }
        />
        <Route
          path="season-version/:id/*"
          element={
            <SuspensedView>
              <SeasonVersionDetails />
            </SuspensedView>
          }
        />
        <Route
          path="series/season/episode/:id/*"
          element={
            <SuspensedView>
              <EpisodeDetails />
            </SuspensedView>
          }
        />
        <Route
          path="payouts"
          element={
            <SuspensedView>
              <Payouts />
            </SuspensedView>
          }
        />
        <Route
          path="contact-requests"
          element={
            <SuspensedView>
              <ContactRequestPage />
            </SuspensedView>
          }
        />
        <Route
          path="contact-requests/:id/*"
          element={
            <SuspensedView>
              <ContactRequestOverview />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
