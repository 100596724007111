/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { KTIcon, toAbsoluteUrl } from "../../../../_metronic/helpers";
import { Link, useLocation, useNavigate, useParams, } from "react-router-dom";
import { useEffect, useState } from "react";
import { PageLink, PageTitle } from "../../../../_metronic/layout/core";
import Loader from "../../../../_metronic/layout/components/Loader";
import { getContactDetailsByIdService } from "../../../../services/contactRequests";
import moment from "moment";

const profileBreadCrumbs: Array<PageLink> = [
    {
      title: "Contact Requests",
      path: "/contact-requests",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },

  ];
const ContactRequestOverview = () => {
  const location = useLocation();
  const params = useParams();

  interface ContactDetails {
    _id: string;
    subject: string;
    name: string;
    email: string;
    message: string;
    status: string;
    created: string;
    isAuthenticated: boolean;
    userId:{
        id: string;
        name: string;
        email: string;
        phone: string;
    }
  }

  const [details, setDetails]  = useState<ContactDetails | null>(null);

  useEffect(() => {
    getContactDetailsById();
  }, []);
  
  const getContactDetailsById = async () => {
    try {
      const res = await getContactDetailsByIdService(params.id);
      if (res.data.status) {
        setDetails(res.data.details);
        console.log(details);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const statusBadgeClasses = {
    pending: 'badge badge-warning',
    active: 'badge badge-info',
    resolved: 'badge badge-success',
    closed: 'badge badge-secondary',
  };
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // Determine the badge class based on the status
  const status = details?.status;
  const badgeClass = status ? statusBadgeClasses[status] : 'badge badge-primary';

  return (
    <>
      {/* Breadcrumb Section */}
      <PageTitle breadcrumbs={profileBreadCrumbs}>Contact Request Details</PageTitle>

      {/* Main Content */}
      {!details ? (
        <Loader />
      ) : (
        <div className="card mb-5 mb-xl-10">
          <div className="card-body pt-9 pb-0">
            <div className="d-flex my-2">
              <h5 className="text-muted w-190px">Request ID:</h5>
              <p className="mx-3">{details?._id}</p>
            </div>
            <div className="d-flex my-2">
              <h5 className="text-muted w-190px">Subject:</h5>
              <p className="mx-3">{details?.subject}</p>
            </div>
            <div className="d-flex my-2">
              <h5 className="text-muted w-190px">Message:</h5>
              <p className="mx-3">{details?.message}</p>
            </div>
            {/* <div className="d-flex my-2">
              <h5 className="text-muted w-190px">Status:</h5>
               <p className="mx-3">
                    <span className={badgeClass}>
                    {status ? capitalizeFirstLetter(status) : 'Unknown'}
                    </span>
                </p>
            </div> */}
            <div className="d-flex my-2">
              <h5 className="text-muted w-190px">Submitted On:</h5>
              <p className="mx-3">{moment(details.created).format(
          "MMM DD YYYY hh:mm A"
        )}</p>
            </div>
            {details?.userId && details?.isAuthenticated && (
              <div className="d-flex my-2">
                <h5 className="text-muted w-190px">User:</h5>
                <Link
                  to={`/users/${details.userId.id}`}
                  className="mx-3 text-decoration-none"
                ><p className="mx-3">{details?.userId?.name}</p></Link>
                <p className="mx-3">{details?.userId?.email}</p>
                <p className="mx-3">{details?.userId?.phone}</p>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export { ContactRequestOverview };
