/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from "react-intl";
import { AsideMenuItem } from "./AsideMenuItem";
import { useAuth } from "../../../../app/modules/auth";

export function AsideMenuMain() {
  const intl = useIntl();
  const { currentUser } = useAuth();
  // console.log("user", currentUser);
  return (
    <>
      <AsideMenuItem
        to="/dashboard"
        icon="element-11"
        title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
      />
      {currentUser?.role === "super-admin" && (
        <>
          <AsideMenuItem to="/users" icon="shield-tick" title="Users" />
          <AsideMenuItem to="/vendors" icon="shield-tick" title="Vendors" />
        </>
      )}
      <AsideMenuItem to="/movies" icon="google-play" title="Movies" />
      <AsideMenuItem to="/series" icon="google-play" title="Series" />
      {currentUser?.role === "super-admin" && (
        <>
          <AsideMenuItem to="/genres" icon="happy-emoji" title="Genres" />
          
        </>
      )}
      <AsideMenuItem to="/requests" icon="happy-emoji" title="Requests" />
      <AsideMenuItem to="/transactions" icon="tag" title="Transactions" />
      {currentUser?.role === "vendor" && (
        <AsideMenuItem to="/sales" icon="tag" title="Sales" />
      )}
      <AsideMenuItem to="/payouts" icon="tag" title="Payouts" />
      <AsideMenuItem to="/contact-requests" icon="happy-emoji" title="Contact Requests" />
    </>
  );
}
