import { createSlice } from "@reduxjs/toolkit";
import { getDashboardChartThunk, getDashboardThunk } from "../thunks/user";
import {
  addEpisodesThunk,
  getCharacterThunk,
  getExchangeThunk,
  getGenreThunk,
  getMovieListThunk,
  getPayoutsThunk,
  getRequestesListThunk,
  getSeriesListThunk,
  getTransactionListThunk,
  getUserListThunk,
  getVendorsListThunk,
  getContactRequestListThunk
} from "../thunks/management";
// import { getUserPaymentMethodsThunk, getUserThunk } from "../thunks/user";
// import { UserState } from "../../utils/types";

// Define a type for the slice state

// Define the initial state using that type
interface State {
  user: any;
  vendors: any;
  movie: any;
  series: any;
  transaction: any;
  requests: any;
  genreList: any;
  characterList: any;
  episodesList: any;
  exchangeRate: any;
  payouts: any;
  contactRequests: any;
}
const initialState: State = {
  user: {
    total: 0,
    userList: [],
  },
  vendors: {
    total: 0,
    userList: [],
  },
  movie: {
    total: 0,
    movieList: [],
  },
  series: {
    total: 0,
    seriesList: [],
  },
  transaction: {
    total: 0,
    transactionList: [],
  },
  requests: {
    total: 0,
    list: [],
  },
  payouts: {
    total: 0,
    list: [],
  },
  genreList: [],
  characterList: [],
  episodesList: [],
  exchangeRate: 1,
  contactRequests: {
    total: 0,
    contactRequestsList: [],
  },
};

export const managementSlice = createSlice({
  name: "management",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserListThunk.fulfilled, (state, action) => {
      state.user = action.payload;
    });
    builder.addCase(getVendorsListThunk.fulfilled, (state, action) => {
      state.vendors = action.payload;
    });
    builder.addCase(getRequestesListThunk.fulfilled, (state, action) => {
      state.requests = action.payload;
    });
    builder.addCase(getMovieListThunk.fulfilled, (state, action) => {
      state.movie = action.payload;
    });
    builder.addCase(getSeriesListThunk.fulfilled, (state, action) => {
      state.series = action.payload;
    });
    builder.addCase(getTransactionListThunk.fulfilled, (state, action) => {
      state.transaction = action.payload;
    });
    builder.addCase(getGenreThunk.fulfilled, (state, action) => {
      state.genreList = action.payload;
    });
    builder.addCase(getCharacterThunk.fulfilled, (state, action) => {
      state.characterList = action.payload;
    });
    builder.addCase(addEpisodesThunk.fulfilled, (state, action) => {
      state.episodesList = action.payload;
    });
    builder.addCase(getExchangeThunk.fulfilled, (state, action) => {
      state.exchangeRate = action.payload;
    });
    builder.addCase(getPayoutsThunk.fulfilled, (state, action) => {
      state.payouts = action.payload;
    });
    builder.addCase(getContactRequestListThunk.fulfilled, (state, action) => {
      // console.log("Slice - Action payload:", action.payload);
      if (action.payload && action.payload.requestsList && typeof action.payload.total === "number") {
        state.contactRequests = action.payload;
      } else {
        // console.warn("Invalid payload received for contactRequests");
      }
    });
  },
});
export default managementSlice.reducer;
